<template>
	<standard-container title="colloqui" admin description="">
		<v-btn fab color="warning" top right absolute title="Aggiungi guida colloquio" @click="newItem">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-row>
			<v-col cols="12">
				<v-list two-line>
					<template v-for="(item, index) in itemList">
						<v-list-item :key="index" @click="editItem(item, index)">
							<v-list-item-content>
								<v-list-item-title v-text="item.TipoColloquio" />
								<v-list-item-subtitle v-text="item.ScopiObiettivi" />
							</v-list-item-content>
							<v-list-item-icon>
								<v-icon v-if="item.New" title="nuovo">
									mdi-new-box
								</v-icon>
								<v-icon v-if="item.Changed" title="modificato">
									mdi-pencil
								</v-icon>
							</v-list-item-icon>
						</v-list-item>
						<v-divider :key="index+'div'" />
					</template>
				</v-list>
			</v-col>
		</v-row>
		<!-- inizio dialog -->
		<v-dialog v-if="currentItem" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentItem.Changed ? 'Modifica' : 'Nuova' }} guida colloquio</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark @click="updateItem(currentItem)">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
						<v-col cols="12" lg="12">
							<v-text-field v-model="currentItem.TipoColloquio" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Tipo Colloquio" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea v-model="currentItem.ScopiObiettivi" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Scopi obiettivi" rows="2" auto-grow />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea v-model="currentItem.Svolgimento" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Svolgimento" rows="2" auto-grow />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea v-model="currentItem.Conclusioni" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Conclusioni" rows="2" auto-grow />
						</v-col>
					</v-row>
					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<debug-panel label="currentItem">
									{{ currentItem }}
								</debug-panel>
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->		
	</standard-container>
</template>

<script>
/***************************************************************************************************************************************/
/** PAGINA ADMIN - quindi non passiamo per vuex per semplificare, tanto sono dati che non ha senso persistere a livello di intera app **/
/***************************************************************************************************************************************/
import StandardContainer from '@/components/standard-container'
import DebugContainer from '@/components/debug-container'
import DebugPanel from '@/components/debug-panel'
export default {
	name: 'AdminColloquiPage',
	components: { StandardContainer, DebugContainer, DebugPanel },
	data: () => {
		return {
			dialog: false,
			itemList: [],
			currentItem: {}
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		currentUser() { return this.$store.getters['currentUser'] },
	},
	mounted() {
		this.loadItems()
	},	
	methods: {
		loadItems() {
			this.$store.dispatch('genericApiGet', 'RisorseUmane/GuidaColloquio/Elenco/500').then((response) => {
				this.itemList = response
			})
		},
		async newItem() {
			let negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			this.currentItem = {
				Id: negativeId,
				Conclusioni: null,
				IdAzienda: this.currentUser.AziendaCorrente.Id,
				ScopiObiettivi: null,
				Svolgimento: null,
				TipoColloquio: null
			}
			this.dialog=true
			console.log('GuidaColloquio/Add', this.currentItem)
			/*
			this.$store.dispatch('genericApiGet', 'RisorseUmane/GuidaColloquio/Add').then((response) => {
				response.Id = negativeId
				this.currentItem = response
				this.currentItem.New = true
				this.dialog=true
				console.log('GuidaColloquio/Add', response)
			}).catch((error) => {
				console.error(error)
			})
			*/
		},
		editItem(item, index) {
			this.currentItem = JSON.parse(JSON.stringify(item))
			this.currentItem.Index = index
			this.currentItem.Changed = true
			this.dialog=true
		},
		updateItem(item) {
			var isNewItem = !item.Index
			this.$store.dispatch('genericApiPost', {apiUrl: 'RisorseUmane/GuidaColloquio/Dettaglio/Save', value: item}).then((updatedList) => {
				//newItem.Changed = !isNewItem
				//newItem.New = isNewItem
				this.dialog=false
				this.itemList = updatedList
			}).catch((error) => {
				console.error(error)
			})
		},
	}
}
</script>
